import React, { useState } from 'react';
import logoZkcandy from '../assets/images/logo-zkcandy.svg';
import iconMenu from '../assets/images/icon-menu.svg';
import iconClose from '../assets/images/icon-close.svg';
import { Link } from 'react-router-dom';

export default function Header() {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    return (
        <nav className='navbar py-3'>
            <div className="container mx-auto">
                <div className='flex items-center justify-between px-3'>
                    <Link to='/' className='logo md:w-[150px] w-[120px] hover:scale-[1.1] eas-in duration-300'>
                        <img className='w-full' src={logoZkcandy} />
                    </Link>

                    <div className={isMenuOpen ? 'hidden btn-menu show' : 'hidden btn-menu'} onClick={toggleMenu}>
                        <img className='menu' src={iconMenu} />
                        <img className='close' src={iconClose} />
                    </div>

                    <div className={isMenuOpen ? 'menu-container show' : 'menu-container'}>
                        <div className='menu flex items-center lg:flex-row flex-col'>
                            <Link onClick={toggleMenu} to='/#homepage' className='menu-item'>Home</Link>
                            <a onClick={toggleMenu} href='https://zkcandy.medium.com/' target='_blank' className='menu-item'>Blog</a>
                            <a onClick={toggleMenu} href='https://docs.zkcandyapi.com/' target='_blank' className='menu-item'>Docs</a>
                            <a onClick={toggleMenu} href='https://portal.zkcandy.io/' target='_blank' className='btn btn-white uppercase mr-4'>Connect To Testnet</a>
                            <a onClick={toggleMenu} href='https://litepaper.zkcandy.io/' target='_blank' className='btn btn-white'>LitePaper</a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}