import React, { useEffect } from "react";
export default function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div class="privacy-policy">
            <div className="container mx-auto relative">
                <h1 class="text-uppercase mb-5 mt-5 font-bold">
                    ZK Candy's Privacy Policy
                </h1>

                <p>This Privacy Policy as amended or otherwise changed from time to time (“<b>Policy</b>”) sets out how ZK Candy Ltd, a company incorporated in the British Virgin Islands and our affiliates (collectively referred to as “<b>ZKCandy</b>”, “<b>we</b>”, “<b>us</b>”, or “<b>our</b>”) collects, uses, processes, and protects your Personal Data collected through your use of our website as well as any marketing campaigns, product feedback forms, surveys, events and sales (collectively “<b>Services</b>”).  </p>

                <p>By accessing our website of any Services, you acknowledge and agree that you have read, understood, and agreed to the terms of this Policy. If you do not agree with any terms hereof, please cease to use or access to our website of the Services.</p>

                <ol>
                    <li>
                        <div class="main-title">Personal Data</div>
                        <p>“<b>Personal Data</b>” or “<b>Personal Information</b>” means any personally identifiable information that can be issued to identify or contact you, which may include without limitation the following information:</p>

                        <ol class="roman">
                            <li>Name</li>
                            <li>Date of birth</li>
                            <li>Email address</li>
                            <li>Phone number</li>
                            <li>Mailing address</li>
                            <li>IP address</li>
                            <li>Wallet ID and associated metadata</li>
                            <li>Cookies and usage data</li>
                        </ol>
                    </li>

                    <li>
                        <div class="main-title">How We Collect Your Personal Data</div>
                        <p>ZK Candy and/or our service provider(s) may obtain or request Personal Data about you in the manner as described below:</p>

                        <ol>
                            <li>
                                <div class="sub-title">Personal Information Collected Automatically</div>
                                <p>When you access to the website or any Services, ZK Candy and/or our service providers may automatically log information about you, your computer or mobile device, and your interaction over time with the websites, our communications, and other online services, such as:</p>

                                <ol class="roman">
                                    <li>Device data including but not limited to your computer’s or mobile device’s operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers (including identifiers used for advertising purposes), language settings, mobile device carrier, radio/network information (e.g., Wi-Fi, LTE, 4G), internet service provider, and general location information such as city, state or geographic area; and</li>
                                    <li>Online activity data including but not limited to pages or screens you viewed, how long you spent on a page or screen, browsing history, navigation paths between pages or screens, information about your activity on a page or screen, access times, and duration of access, and whether you have opened our marketing emails or clicked links within them, information about the links you click, the types of content you interact with, the frequency and duration of your activities, and other information about how you use our website and our Services.</li>
                                </ol>
                            </li>

                            <li>
                                <div class="sub-title">Personal Information You May Be Asked to Provide</div>
                                <p>When you access to the website or any Services, you may be requested to provide the following information to us:</p>

                                <ol class="roman">
                                    <li>Contact and account information, including but not limited to your first and last name, email address, username(s), phone number, date of birth, photographic identification, government issued identification and other contact details.</li>
                                    <li>Feedback or correspondence, including but not limited to information you provide when you contact us with questions, feedback, product reviews, or otherwise correspond with us online.</li>
                                    <li>Usage information, including but not limited to information about how you use the websites and interact with them, including information associated with any content you upload to the websites or otherwise submit to us, and information you provide when you use any interactive features of the websites. </li>
                                    <li>Marketing information, including but not limited to your preferences for receiving communications about our activities, events, publications, and details about how you engage with our communications.</li>
                                    <li>Information we are required to collect by any applicable law, rule, or regulation in connection with the Services.</li>
                                    <li>Other information that we may collect which is not specifically listed herein, but which we will use in accordance with this Policy or as otherwise disclosed at the time of collection.</li>
                                </ol>
                            </li>

                            <li>
                                <div class="sub-title">Personal Information Collected from Other Sources</div>
                                <p>We may obtain Personal Information from other sources, including through third-party services and organizations.  For example, if you access our Services through a third-party application, a third-party login service, third party identity verification services, or a social networking site, we may collect personal information about you from that third-party application that you have made available via your privacy settings.</p>
                            </li>

                            <li>
                                <div class="sub-title">Blockchain Transaction Data</div>
                                <p>The nature of a public blockchain means that certain information is publicly available, including but not limited to: your wallet address; the address of a sender initiating a transaction; the address of a recipient (whether an Ethereum address or a smart contract address); the maximum amount of gas fees that the sender is willing to allocate for executing the transaction; the price the sender is willing to pay per unit of gas; the nonce (a sequential number issued by the sender’s address); the cryptographic signature generated using the sender’s private key; the IP address from the requester (visible only to remote procedure call nodes); and any additional data needed for the transaction, such as invoking functions in a smart contract or providing arguments for those functions. When you authorize (i.e., use a crypto wallet to “sign”) a blockchain transaction through any of the websites, you are authorizing us to collect and use all information associated with that transaction which we will do in accordance with this Policy. Note that we are not able to control whether or how third parties use information that is stored on the blockchain, and we expressly disclaim responsibility for any such activities by third parties.</p>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <div class="main-title">Tools Used for Automatic Data Collection</div>
                        <p>We may use various techniques and tools to automatically collect information through this website or any of our Services, including but not limited to:</p>

                        <ol class="roman">
                            <li>
                                <div class="sub-title">Cookies</div>
                                <p>Cookies are small text files that websites store on a visitor’s device to uniquely identify the visitor’s browser or to store information or settings in the browser for the purpose of helping you navigate between pages efficiently, remembering your preferences, enabling functionality, helping us understand user activity and patterns, and facilitating online advertising.</p>
                            </li>

                            <li>
                                <div class="sub-title">Local storage technologies</div>
                                <p>such as HTML5, which provide cookie-equivalent functionality but can store larger amounts of data, including on your device outside of your browser in connection with specific applications,</p>
                            </li>

                            <li>
                                <div class="sub-title">Pixel tags / Web beacons</div>
                                <p>A pixel tag or web beacon (also known as clear GIFs) may be used to collect information in relation to the engagement on the website or our Services and demonstrate where a webpage or email was accessed or opened, or that certain content was viewed or clicked.</p>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <div class="main-title">How We Use Your Personal Information</div>
                        <p>We may use your Personal Information for the following purposes:</p>

                        <ol class="roman">
                            <li>
                                <div class="sub-title">Operation of the website</div>
                                <p>We use your Personal Information to provide, operate, maintain, secure and improve the Websites; provide information about the Websites; manage and develop our business and operations; communicate with you about the Websites, including by sending you announcements, updates, security alerts, and support and administrative messages; understand your needs and interests, and personalize your experience; and respond to your requests, questions and feedback and to provide support. We use such Personal Information to fulfil our obligations to you or when it is in our legitimate business interests to do so.  </p>
                            </li>

                            <li>
                                <div class="sub-title">Personalization</div>
                                <p>We may use your Personal Information to personalize your experience accessing this website of any Services, including recommending services, content, and offers based on your preferences and behaviors.</p>
                            </li>

                            <li>
                                <div class="sub-title">Research, development, and enhancement</div>
                                <p>We may use your Personal Data to analyze, develop, enhance and improve the website and our Services or develop new services or products by studying the use of the website and our Services, and analyzing interest and engagement in our Services.</p>
                            </li>

                            <li>
                                <div class="sub-title">Marketing and advertising purposes</div>
                                <p>Except where consent is required, and unless you have opted out of receiving marketing communications, it is in our and our marketing partners’ legitimate interests to collect and use your Personal Information for marketing and advertising purposes. We or our advertising partners may from time-to-time send you direct marketing communications as permitted by law, including, but not limited to, notifying you of special promotions, offers and events via email, custom audiences advertising, and “interest-based” or “personalized advertising”.</p>
                            </li>

                            <li>
                                <div class="sub-title">Compliance with laws, fraud prevention, and safety/security</div>
                                <p>We will use or disclose your Personal Information if we reasonably believe that it is required or necessary to comply with the applicable laws, regulations or rules, lawful requests, and legal process, such as to respond to subpoenas or requests from any court of a competent jurisdiction and government authorities. We may also use your Personal Information to (a) protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims); (b) enforce the terms and conditions that govern the websites; (c) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity. In these circumstances, we will process your Personal Information to either comply with a legal obligation or when it is in our legitimate business interests. Furthermore, the IP addresses may be evaluated, together with other data, in case of attacks on the network infrastructure or other unauthorized use or misuse of the websites, for the purpose of intelligence and protection, and if appropriate, used in criminal proceedings for identification and civil and criminal proceedings against the relevant users. This is our legitimate interest in the processing of data in the sense of Art. 6 Par. 1 lit. of the General Data Protection Regulation (GDPR).</p>
                            </li>

                            <li>
                                <div class="sub-title">To create anonymous data</div>
                                <p>It is in our legitimate business interests to create anonymous data from Personal Information collected by removing information that makes the data personally identifiable to you. We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve the Websites and promote our business.</p>
                            </li>

                            <li>
                                <div class="sub-title">With your consent</div>
                                <p>We may use your Personal Information for such purposes as disclosed to you at the time you provide your Personal Information or with your consent.</p>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <div class="main-title">Disclosure of Personal Information</div>
                        <p>We will not make available or disclose your Personal Data to any third parties, save for the provider of the website, unless you have expressly consented to it or if we are required to disclose under any applicable laws, regulations, or rules, or where it is necessary to enforce our rights concerning a contractual relationship. This is our legitimate interest in the processing of data in the sense of Art. 6 Par. 1 lit. f GDPR. Notwithstanding the aforementioned, we may disclose your Personal Data for the following purposes:</p>

                        <ol class="roman">
                            <li>We may disclose your Personal Data to our service provider(s) (whether individuals or companies) that provide services on our behalf or for the purposes of the operation of the website (such as customer support, hosting, analytics, email delivery, marketing, identity verification, and database management services).</li>
                            <li>We may disclose your Personal Data to Web3 projects and collaborators as may be necessary for the provision of this website and/or the Services.</li>
                            <li>We may disclose your Personal Data to our professional advisors including but not limited to lawyers, bankers, auditors, and insurers, as may be necessary in the course of the professional services that they render to us.</li>
                            <li>We may disclose your Personal Data for compliance, fraud prevention, or security purposes, or to defend or investigate any illegal or suspected violations of any of ZK Candy’s terms and conditions, or for the protection of the rights, safety, and security of other users.</li>
                            <li>We may disclose your Personal Data where it is required in the course of any negotiation of any merger, financing, acquisition, or dissolution transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets.</li>
                            <li>If you conduct financial transactions by credit card or debit card through the website, we may forward your credit/debit card information to the credit/debit card issuer and the credit/debit card acquirer. If you choose to use a credit/debit card, you may be asked to provide all the necessary information. The legal basis for passing on the data lies in the fulfillment of an agreement in the sense of Art. 6 Par. Lit. b GDPR.</li>
                        </ol>
                    </li>

                    <li>
                        <div class="main-title">Transfer of Personal Data</div>
                        <p>By using the websites, you understand and acknowledge that we may transfer your Personal Information to service providers or other third parties who are located in various countries globally. This includes service providers of the websites and e-commerce providers such as payment solution providers to assist us in the processing of your online payments. When we transfer your Personal Data to third parties abroad for the purposes of the data processing described in this Privacy Policy, unless we can rely on a derogation provided under data protection law, we will ensure that relevant safeguards are in place to afford adequate protection for your Personal Information and we will comply with applicable data protection laws, in particular if you reside in the Cayman Islands, UK or the EEA by relying on a UK government adequacy regulation or adequacy decision by the European Commission, or on contractual protections for the transfer of your Personal Information. We may also rely on specific contracts approved by the European Commission which offer Personal Data protection. For more information about how we transfer Personal Data internationally, please contact us as set out in the “Contact Us” section below.</p>
                    </li>

                    <li>
                        <div class="main-title">Your Rights</div>
                        <ol>
                            <li>
                                <p>Subject to the applicable data protection laws of the jurisdiction you are subject to, the rights available to you in relation to your Personal Data are as follows:</p>

                                <ol class="roman">
                                    <li>
                                        <div class="sub-title">Opt-out of marketing communications</div>
                                        <p>You may opt out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of any marketing email. You may continue to receive service-related and other non-marketing emails.</p>
                                    </li>

                                    <li>
                                        <div class="sub-title">Access right</div>
                                        <p>Have access to the Personal Information we hold about you (including, if applicable, to obtain such information in a structured and commonly-used machine readable format).</p>
                                    </li>

                                    <li>
                                        <div class="sub-title">Request correction</div>
                                        <p>Request to have any incomplete or inaccurate Personal Information we hold about you corrected, subject to the fulfilment of any verification requirements.</p>
                                    </li>

                                    <li>
                                        <div class="sub-title">Request for deletion</div>
                                        <p>Request to delete or remove Personal Information where such information is no longer required by us.</p>
                                    </li>

                                    <li>
                                        <div class="sub-title">Objection right</div>
                                        <p>Object to the processing of your Personal Data unless it is necessary, or we have compelling legitimate interest to process such Personal Data.</p>
                                    </li>

                                    <li>
                                        <div class="sub-title">Consent withdrawal</div>
                                        <p>To withdraw your consent to us to process your Personal Data, where we rely on your consent for the processing of such Personal Data. For the avoidance of doubt, this shall not affect the lawfulness of any Personal Data processing prior to your withdrawal.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>We may require you to verify your identity in order to effect any requests made. In addition, we may refuse any request if we reasonably believe that there is a valid legal reason to do so or in order to comply with any applicable laws. For more information on your rights, please contact us using the details in the “<b>Contact Us</b>” section below.</li>
                            <li>Please note that parts of the website incorporate blockchain technology. A blockchain is a shared and synchronized digital database that is stored on multiple nodes (computers that store a local version of the database) and which are not controlled by us. As, by design, data on a blockchain cannot be changed or deleted, your ability to exercise your data protection rights such as your right to erasure, or your rights to object or restrict processing with respect to on-chain Personal Data may be affected.</li>
                        </ol>
                    </li>

                    <li>
                        <div class="main-title">Security</div>
                        <p>We take the protection of your Personal Data seriously. We protect Personal Data against manipulation, partial or complete loss, misuse, and unauthorized access with appropriate physical, technological and organizational safeguards and security measures. However, data transmission over the internet (including via blockchain) cannot be guaranteed as completely secure. We do not warrant or guarantee the security of any Personal Data transmitted to us via the internet or through a blockchain. Please note that any data transmission on the internet (including through blockchains) is generally not secure or may be accessed by third parties, and we accept no liability for data transmitted to us via the internet or through a blockchain.</p>
                    </li>

                    <li>
                        <div class="main-title">Job Applicants</div>
                        <p>When you submit a job application to us or visit the “Join Us” or “Careers” portion of our website, we collect the information that you provide to us in connection with your job application. This includes business and personal contact information, professional credentials and skills, educational and work history, and other information of the type that may be included in a resume. This may also include diverse information that you voluntarily provide. When required, we will only process sensitive personal information with your consent. We use this information to facilitate our recruitment activities and process employment applications, such as by evaluating a job candidate for an employment activity, and monitoring recruitment statistics. We may also use this information to provide improved administration of the website, and as otherwise necessary (a) to comply with relevant laws or to respond to subpoenas or warrants served on us; (b) to protect and defend the rights or property of us or others; (c) in connection with a legal investigation; and/or (d) to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, or our Terms of Service. In these circumstances, we will process your Personal Information to either enter into a contract with you, to comply with a legal obligation or when it is in our legitimate business interests.</p>
                    </li>

                    <li>
                        <div class="main-title">Retention of Personal Data</div>
                        <p>We store the Personal Information we collect as described in this Policy for as long as you use our Services, or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and comply with applicable laws.  </p>
                    </li>

                    <li>
                        <div class="main-title">Children’s Privacy</div>
                        <p>The website and Services are not intended for use by children under 13 years of age and we do not knowingly collect Personal Information from children. If we learn that we have collected Personal Information from a child under 13 without the consent of the child’s parent or guardian as required by law, we will delete it. If you are a parent or guardian and believe that your child under 13 has provided any Personal Information to us without your consent, you may contact us as described in the “Contact Us” section below.</p>
                    </li>

                    <li>
                        <div class="main-title">Amendments of Changes to this Policy</div>

                        <ol>
                            <li>We reserve the right to change, amend, modify, and/or revise this Policy at any time. If there are any material changes to this Policy, we will notify you by updating the date of this Policy and posting it on the website. We may also notify you by other means which we reasonably believe are likely to reach you, such as e-mail or other manners.</li>
                            <li>Any amendment, modification, or revision to this Policy will take effect upon the publication of the new terms and/or upon implementation of the updated terms on the website unless otherwise specified.</li>
                            <li>You understand and agree that you will be deemed to have accepted the updated Policy by continuing to use or access the website and/or our Services.</li>
                        </ol>
                    </li>

                    <li>
                        <div class="main-title">Complaints</div>
                        <p>If you have a concern about our privacy practices, including the way we handle your Personal Information, please contact us at <a href="mailto:legal@zkcandy.io">legal&#64;zkcandy.io</a>. We will endeavor to respond to your complaint as soon as possible. This is without prejudice to your right to report it to any data protection authority that is authorized to hear those concerns. </p>
                    </li>

                    <li>
                        <div class="main-title">Contact Us</div>
                        <p>ZK Candy is the data controller in respect of your Personal Data processed in connection with the website and the Services under this Policy. If you have any queries about our privacy practices, this Policy or would like to exercise your rights with respect to your Personal Data as stated herein, please contact us at <a href="mailto:legal@zkcandy.io">legal&#64;zkcandy.io</a>.</p>
                    </li>
                </ol>
            </div>
        </div>
    );
}