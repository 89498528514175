import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <div className="footer">
            <div className="container mx-auto py-3 flex justify-center md:text-[1rem] text-[.6rem] text-center md:-mt-[3rem] -mt-[3.25rem] items-center">
            <Link className="px-1 w-1/3" to='/terms-conditions'>Terms of Service</Link>
            <Link className="px-1 border-r-2 border-l-2 w-1/3" to='/privacy-policy'>Privacy Policy</Link>
            <Link className="px-1 w-1/3" to='https://zkcandy.notion.site/ZKcandy-Brand-Kit-6161ad48f6814af1a4cb57208bf9376f?pvs=4' target="_blank">Brand Kit</Link>
            </div>
        </div>
    );
}